<template>
  <div class="MonitorFormRequestBody">
    <base-select-tag label="Body"
                     v-model="selectedType"
                     :can-be-empty="true"
                     :options="requestBodyTypes" />

    <template v-if="selectedType">
      <base-textarea v-model="bodyCopy.body"
                     description="Optional request body. Only sent for appropriate methods like post."
                     :placeholder="bodyPlaceholder"
                     rows="5"
                     v-if="selectedType.value === 'raw'" />

      <base-textarea v-model="bodyCopy.json"
                     name="json"
                     rules="json"
                     description="Optional request json data. Only sent for appropriate methods like post."
                     :placeholder="bodyPlaceholder"
                     rows="5"
                     v-if="selectedType.value === 'json'" />

      <template v-if="selectedType.value === 'form_params'">
      <div class="form-params">
        <div class="param align-items-center"
             v-for="(param, index) in bodyCopy.formParams"
             :key="index">
          <div class="param-name">
            <base-textbox v-model="param.name"
                          placeholder="Param Name" />
          </div>
          <div class="param-value">
            <base-textbox v-model="param.value"
                          placeholder="Param Value" />
          </div>
          <div class="remove-param">
            <icon-cross height="24"
                        width="24"
                        @click="removeFormParam(index)" />
          </div>
        </div>

        <a href="#" @click.prevent="addFormParam">Add new param</a>
      </div>
    </template>
    </template>
  </div>
</template>

<script>
import { cloneObject } from '@/services/utils.js'

export default {
  model: {
    prop: 'body',
    event: 'update'
  },

  props: {
    body: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      requestBodyTypes: [
        {
          value: 'raw',
          label: 'Raw'
        },
        {
          value: 'json',
          label: 'JSON'
        },
        {
          value: 'form_params',
          label: 'Form Params'
        }
      ],
      selectedType: null,

      bodyCopy: cloneObject(this.body)
    }
  },

  created () {
    if (this.bodyCopy.type) {
      this.selectedType = this.requestBodyTypes.find(type => type.value === this.bodyCopy.type)
    }
  },

  methods: {
    addFormParam () {
      if (!this.bodyCopy.formParams) {
        this.bodyCopy.formParams = []
      }

      this.bodyCopy.formParams.push({
        name: '',
        value: ''
      })
    },

    removeFormParam (index) {
      this.bodyCopy.formParams.splice(index, 1)
    }
  },

  computed: {
    bodyPlaceholder () {
      if (!this.selectedType) {
        return null
      }

      switch (this.selectedType.value) {
        case 'raw':
          return 'Raw Data'
        case 'json':
          return 'Json Data'
        default: return ''
      }
    }
  },

  watch: {
    bodyCopy: {
      handler: function (value) {
        this.$emit('update', value)
      },
      deep: true
    },

    selectedType (type) {
      this.bodyCopy.type = type?.value
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorFormRequestBody {
    /deep/ .BaseSelectTag .option {
      margin-bottom: 0;
    }

    .form-params {
      margin-bottom: 20px;

      .param {
        display: flex;
        margin-bottom: 10px;
      }

      .param-name {
        flex: 1 0;
      }

      .param-value {
        flex: 2 0;
        margin-left: 10px;
      }

      .remove-param {
        flex: 0 0 20px;
        margin-left: 10px;
        cursor: pointer;
      }

      .base-text-box {
        margin: 0;
      }
    }
  }
</style>
