<template>
  <div class="MonitorSettingsGeneral">
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <form @submit.prevent="passes(onFormSubmit)">
        <base-textbox label="Notification Delay in minutes"
                      placeholder="0"
                      description="We will notify you if your site is down for specified number of minutes"
                      rules="numeric"
                      v-model="settingsData.offline_notification_delay"/>

        <base-slider label="Max timeout in seconds"
                     v-model="settingsData.request.timeout"
                     description="Time we should wait till your url respond. Otherwise we will consider the check as failed by timeout."
                     :min="0.5"
                     :max="30"
                     :interval="0.5"
                     tooltip="always"/>

        <base-select-tag label="Check frequency"
                         v-model="selectedUptimeCheckFrequency"
                         :options="uptimeCheckFrequencies"/>

        <base-checkbox label="Enable SSL check"
                       v-model="settingsData.ssl_check"/>
        <p class="enable-ssl-description">Disable SSL checking to stop receiving SSL alerts.</p>
    <div class="toolbar">
      <base-button ref="submit"
                   type="submit"
                   class="button-update submit success"
                   :loading="saving">Update
      </base-button>
    </div>
      </form>
    </ValidationObserver>
  </div>

</template>
<script>
import { mapState } from 'vuex'
import { cloneObject } from '@/services/utils.js'
import tippy from 'tippy.js'
import profileApi from '@/api/profileApi'

export default {
  props: {
    settings: {
      type: Object
    }
  },

  data () {
    return {
      saving: false,
      updateButtonTippy: null,
      settingsData: null,
      uptimeCheckFrequencies: [
        {
          value: 30,
          label: '30 seconds'
        },
        {
          value: 60,
          label: '1 minute'
        },
        {
          value: 300,
          label: '5 minutes'
        },
        {
          value: 600,
          label: '10 minutes'
        },
        {
          value: 900,
          label: '15 minutes'
        },
        {
          value: 1800,
          label: '30 minutes'
        },
        {
          value: 3600,
          label: '1 hour'
        },
        {
          value: 86400,
          label: '24 hours'
        }
      ],

      selectedUptimeCheckFrequency: null
    }
  },

  created () {
    this.checkUserPlanMonitorsFrequency()
    this.selectedUptimeCheckFrequency = this.uptimeCheckFrequencies.find(
      frequency => frequency.value === this.settings.uptime_check_frequency
    )

    this.settingsData = cloneObject(this.settings)
  },

  methods: {
    checkUserPlanMonitorsFrequency () {
      const index = this.uptimeCheckFrequencies.findIndex(el => el.value === this.user.subscription_plan.check_interval)
      this.uptimeCheckFrequencies.splice(0, index)
    },

    async onFormSubmit () {
      const data = {
        uptime_check_frequency: this.selectedUptimeCheckFrequency.value,
        offline_notification_delay: this.settingsData.offline_notification_delay,
        ssl_check: this.settingsData.ssl_check,
        request: {
          timeout: this.settingsData.request.timeout
        }
      }
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'Settings were updated successfully',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })
      this.saving = true
      try {
        const response = await profileApi.saveMonitorsGeneralSettings(data)
        this.triggerTippy()
        this.$emit('update', response)
      } catch (e) {
        this.showFailed()
      }
      this.saving = false
    },

    triggerTippy () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    showFailed () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'There was an error',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })

      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>
<style lang="scss" scoped>
.MonitorSettingsGeneral {
  max-width: 550px;

  .toolbar {
    position: sticky;
    bottom:0;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px 0;
    backdrop-filter: blur(3px);
  }
}
.enable-ssl-description {
  margin-top: -17px;
  font-size: 12px;
  opacity: .6;
  margin-left: 28px;
  line-height: 17px;
}
</style>
