<template>
  <div class="MonitorFormRequestHeaders">
    <div class="title">Request Headers</div>
    <header-list v-model="headersCopy" />
  </div>
</template>

<script>
import HeaderList from '@/components/Common/HttpHeaderList.vue'
import { cloneObject } from '@/services/utils.js'

export default {
  components: {
    HeaderList
  },

  model: {
    prop: 'headers',
    event: 'update'
  },

  props: {
    headers: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      headersCopy: []
    }
  },

  created () {
    if (Array.isArray(this.headers) && this.headers.length !== 0) {
      this.headersCopy = cloneObject(this.headers)
    } else {
      this.headersCopy = [{
        name: '',
        value: ''
      }]
    }
  },

  watch: {
    headersCopy: function (headers) {
      this.$emit('update', headers.filter(header => header.name))
    },
    deep: true
  }
}
</script>

<style lang="scss" scoped>
  .title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }
</style>
