<template>
  <div class="HttpHeaderList">
    <div class="header align-items-center"
         v-for="(header, index) in headersCopy"
         :key="index">
      <div class="header-name">
        <base-textbox v-model="header.name"
                      placeholder="Header Name" />
      </div>
      <div class="header-value">
        <base-textbox v-model="header.value"
                      placeholder="Header Value" />
      </div>
      <div class="remove-header">
        <icon-cross height="24"
                    width="24"
                    class="delete-button"
                    @click="remove(index)" />
      </div>
    </div>
    <!-- <a href="#" @click.prevent="add">Add new header</a> -->

    <base-button color="primary"
                 class="small is-outlined"
                 @click.prevent="add">Add new header</base-button>
  </div>
</template>

<script>
import { cloneObject } from '@/services/utils.js'

export default {
  model: {
    prop: 'headers',
    event: 'update'
  },

  props: {
    // Every header item should have name and value properties
    /*
       [{
           name: 'Content-Type',
           value: 'application-json'
       }]
     */
    headers: {
      required: true,
      type: Array
    }
  },

  data () {
    return {
      headersCopy: cloneObject(this.headers)
    }
  },

  methods: {
    add () {
      this.headersCopy.push({
        name: '',
        value: ''
      })
    },

    remove (index) {
      this.headersCopy.splice(index, 1)
    }
  },

  watch: {
    headersCopy: {
      handler: function (headers) {
        this.$emit('update', headers.filter(header => header.name))
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .HttpHeaderList {
    margin-bottom: 20px;

    .header {
      display: flex;
      margin-bottom: 10px;
    }

    .header-name {
      flex: 1 0;
    }

    .header-value {
      flex: 2 0;
      margin-left: 10px;
    }

    .remove-header {
      flex: 0 0 20px;
      margin-left: 10px;
      cursor: pointer;
      height: 24px;
    }

    .base-text-box {
      margin: 0;
    }
  }
</style>
