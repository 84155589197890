<template>
  <div>
      <div class="tabs">
          <div class="tab" v-for='(tab, index) in tabs'
              :key='tab.title'
              @click='selectTab(index)'
              :class='[{"tab__selected": (index === selectedIndex)}, tab.className]'>
            <div class="title">{{tab.title}}</div>
            <div class="description">{{tab.description}}</div>
          </div>
      </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i

      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tabs {
  display: flex;
  margin-bottom: 20px;
  .tab {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 6px;

    font-size: 15px;
    color: #777;
    border: 1px solid #E9EBEC;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

    &:not(:first-child) {
      margin-left: 20px;
    }

    transition: 0.2s all;

    &:hover, &.tab__selected {
      background-color: map-get($colors, purple-1);
      cursor: pointer;
      color: #fff;
    }

    &.tab__selected {
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
    }

    .title {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }

    .description {
      font-size: 14px;
      opacity: .8;
      line-height: 1.25;
    }
  }
}
</style>
