<template>
  <div class="MonitorSettingsRequest">
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <form @submit.prevent="passes(onFormSubmit)">
        <base-dropdown label="Request type"
                       value="HTTP"
                       id='typeBox'
                       item-label="title"
                       :searchable="false"
                       track-by="type"
                       v-model="selectedRequestType"
                       :options="requestMethods"
                       @select="$emit('update:request_type', $event)"/>
        <div v-if="selectedRequestType.type === 'http'">
          <base-dropdown label="HTTP Method"
                         item-label="title"
                         :searchable="false"
                         track-by="type"
                         v-model="selectedHttpMethod"
                         :options="httpMethods"/>

          <request-body v-model="requestBody"/>

          <request-headers v-model="request.headers"/>
        </div>
        <base-textbox v-if="selectedRequestType.type === 'tcp'"
                      label ="Ports"
                      v-model="request.tcp_ports"
                      description="Add the ports to be checked. E.g.: 80,433,200"
        />
        <div class="toolbar">
          <base-button ref="submit"
                       type="submit"
                       class="button-update submit success"
                       :loading="saving">Update
          </base-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { cloneObject } from '@/services/utils'
import RequestBody from '@/components/Monitor/MonitorSettings/MonitorSettingsRequest/RequestBody.vue'
import RequestHeaders from '@/components/Monitor/MonitorSettings/MonitorSettingsRequest/RequestHeaders.vue'
import { bus } from '@/main'
import tippy from 'tippy.js'
import profileApi from '@/api/profileApi'

export default {
  components: { RequestHeaders, RequestBody },
  props: {
    settings: {
      type: Object
    }
  },

  data () {
    return {
      request: {
        type: '',
        method: '',
        body: '',
        timeout: '',
        headers: [],
        tcp_ports: ''
      },

      httpMethods: [
        {
          type: 'get',
          title: 'GET'
        },
        {
          type: 'post',
          title: 'POST'
        },
        {
          type: 'put',
          title: 'PUT'
        },
        {
          type: 'patch',
          title: 'PATCH'
        },
        {
          type: 'delete',
          title: 'DELETE'
        },
        {
          type: 'head',
          title: 'HEAD'
        },
        {
          type: 'options',
          title: 'OPTIONS'
        }
      ],

      selectedHttpMethod: null,
      selectedRequestType: null,
      updateButtonTippy: null,
      saving: false,
      savingError: false,

      requestMethods: [
        {
          type: 'http',
          title: 'HTTP'
        },
        {
          type: 'tcp',
          title: 'TCP'
        },
        {
          type: 'icmp',
          title: 'ICMP'
        }
      ]
    }
  },

  created () {
    this.initRequestType()
    this.initRequestAttributes()
    this.initTcpPorts()
  },

  methods: {
    async onFormSubmit () {
      const data = {
        type: this.selectedRequestType?.type,
        method: this.selectedHttpMethod?.type,
        headers: this.request.headers,
        tcp_ports: this.request.tcp_ports,
        body_type: this.request.body_type,
        body_raw: this.request?.body_raw?.replace(/(?<!\r)\n/g, '\r\n'),
        body_json: this.request.body_json,
        body_form_params: this.request.body_form_params
      }

      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'Settings were updated successfully',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })
      this.saving = true
      try {
        const response = await profileApi.saveMonitorsGeneralRequestSettings({ request: data })
        this.triggerTippy()
        this.$emit('update', response)
      } catch (e) {
        this.showFailed()
      }
      this.saving = false
    },

    triggerTippy () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    showFailed () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'There was an error',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })

      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    initRequestAttributes () {
      this.selectedHttpMethod = this.httpMethods.find(
        method => method.type === this.settings.request.method
      )
      this.request = cloneObject(this.settings.request)

      if (!this.request.headers) {
        this.request.headers = [{
          name: '',
          value: ''
        }]
      }
    },

    initRequestType () {
      this.selectedRequestType = this.requestMethods.find(
        method => method.type === this.settings.request.type
      )
    },

    initTcpPorts () {
      this.request.tcp_ports = this.settings.request.tcp_ports
    }
  },

  computed: {
    requestBody: {
      get () {
        return {
          type: this.settings.request.body_type,
          body: this.settings.request.body_raw,
          json: this.settings.request.body_json,
          formParams: this.settings.request.body_form_params
        }
      },

      set (data) {
        this.request.body_type = data.type
        this.request.body_raw = data.body
        this.request.body_json = data.json
        this.request.body_form_params = data.formParams
      }
    }
  },

  watch: {
    selectedHttpMethod (method) {
      this.request.method = method.type
    },
    selectedRequestType (val) {
      this.request.type = val.type
      if (val.type === 'http') {
        bus.$emit('ResponseContent', 'block')
      } else {
        bus.$emit('ResponseContent', 'none')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MonitorSettingsRequest {
  max-width: 550px;

  .toolbar {
    position: sticky;
    bottom:0;
    padding: 15px 0;
    backdrop-filter: blur(3px);

    margin-top: 20px;
  }
}
</style>
